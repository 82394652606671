header#masthead {
  background: rgba(0, 0, 0, 0.8);
}
header#masthead #site-navigation {
  margin: 0 auto;
}

.course-box {
  border: 1px solid #444;
  padding: 12px;
  height: 30rem;
  background-color: rgba(25, 25, 25, 0.9);
  overflow: hidden;
  position: relative;
}
.course-box:hover {
  background-color: rgba(25, 25, 25, 0.85);
  color: #eee;
}
.course-box .title {
  position: absolute;
  top: 32px;
  text-align: center;
  width: 95%;
}

.course-box .course-type {
  font-size: 16px;
  letter-spacing: 1.5px;
  color: #aaa;
  position: absolute;
  left: 5px;
  top: 3px;
  margin: 0;
}

.course-box .categories {
  font-size: 0.95em;
  position: absolute;
  left: 8px;
  bottom: 8px;
  color: #bbb;
  text-align: center;
  width: 95%;
}

.course-box .course-note {
  background-color: rgba(255, 145, 0, 0.5);
  transform: rotate(45deg);
  text-align: center;
  position: absolute;
  height: 79px;
  display: inline;
  padding: 50px 5px 5px 5px;
  right: -86px;
  top: -27px;
  width: 200px;
  color: #222;
}
.project-post {
  background-position: center;
  background-size: cover;
}

.project-post .categories:hover {
  color: #888;
}

  .project-post.bootstrap {
  background-image: url('../img/logos/bootstrap-stack-square.png');
}

.project-post.angular {
  background-image: url('../img/logos/angularjs.jpg');
}

.project-post.vue {
  background-image: url('../img/logos/vuejs-square.png');
}

.project-post.azure {
  background-image: url('../img/logos/azure.jpg');
}

.project-post.dotnet,
.project-post.aspnet,
.project-post.aspnetcore {
  background-image: url('../img/logos/netcore.jpg');
}

.project-post.webdevelopment {
  background-image: url('../img/logos/webdev.jpg');
}

.project-post.javascript {
  background-image: url('../img/logos/javascript.jpg');
}

.project-post.architecture {
  background-image: url('../img/logos/architecture.jpg');
}

.gt-btn {
  letter-spacing: 2px;
  font-size: 24px;
}

.section-title {
  margin-top: 18px;
}

.ih-50 {
  max-height: 50px;
}

#contact-form .gt-btn.primary-btn[disabled] {
  background-color: #222 !important;
  cursor: not-allowed;
  color: #444 !important;
}

#contact-form .gt-btn.primary-btn[disabled]:hover , #contact-form .gt-btn.primary-btn[disabled] .btn-text:hover {
  color: #444 !important;
}
